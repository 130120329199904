import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Element } from 'react-scroll';
import { messageInfo } from '../messageBlock';

export const Contato = () => {
  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const messageSent = (t) => {
    console.log(t);
    messageInfo.update(true, 'Mensagem Enviada!');
  }

  const sendMail = (values) => {
    const { name, mail, message } = values;
    const url = '/mail.php';
    const params = {
      name,
      mail,
      message
    };

    const query = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');

    fetch(`${url}?${query}`)
      .then(data => data.text())
      .then((t) => messageSent(t))
      .catch(err => console.log(err))
  }

  const submit = (event) => {
    event.preventDefault();

    const form = event.srcElement;
    const name = form[0].value;
    const mail = form[1].value;
    const message = form[2].value;

    let hasError = validateName(name);
    hasError = validateMail(mail) || hasError;
    hasError = validateMsg(message) || hasError;

    if (hasError) {
      messageInfo.update(true, 'Por favor, preencher os campos requeridos');
    } else {
      sendMail({ name, mail, message });
    }
  };

  const validateName = (value) => {
    const res = value === '';
    setNameError(value === '');
    return res;
  }

  const validateMail = (value) => {
    const res = value === '';
    setMailError(res);
    return res;
  }

  const validateMsg = (value) => {
    const res = value === '';
    setMsgError(res);
    return res;
  }

  const onNameChange = (event) => {
    const value = event.srcElement.value;
    validateName(value);
  }

  const onMailChange = (event) => {
    const value = event.srcElement.value;
    validateMail(value);
  }

  const onMsgChange = (event) => {
    const value = event.srcElement.value;
    validateMsg(value);
  }

  return (
    <>
      <Element data-aos='fade' name='contato' id='contato' class='block'>
        <div class='block-content'>
          <h1 class='title'>Contato</h1>
          <form onSubmit={submit}>
            <div class='inp-box'>
              <div class='inp-title'>Nome</div>
              <input type='text' onChange={onNameChange} class={nameError ? 'error' : ''} />
            </div>
            <div class='inp-box'>
              <div class='inp-title'>Email</div>
              <input type='email' onChange={onMailChange} class={mailError ? 'error' : ''} />
            </div>
            <div class='inp-box'>
              <div class='inp-title'>Mensagem</div>
              <textarea rows='10' onChange={onMsgChange} class={msgError ? 'error' : ''} />
            </div>
            <div class='inp-box'>
              <input type='submit' value='Enviar' />
            </div>
          </form>
        </div>
      </Element>
    </>
  );
}
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { tabManager } from '../helpers/tabManager';
import { Atividades } from './tabs/atividades';
import { Empresa } from './tabs/empresa';
import { Casos } from './tabs/casos';
import { Contato } from './tabs/contato';
import { MessageBlock } from './messageBlock';
import { useMediaQuery } from 'react-responsive';

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  useEffect(() => {
    tabManager.getMainBlocks();
    const html = document.body.parentElement;

    const addSnapScroll = () => {
      html.style.scrollSnapType = 'y';
    }

    const removeSnapScroll = () => {
      html.style.scrollSnapType = '';
    }

    if (!isMobile) {
      addSnapScroll();
      tabManager.addBeginCallback(removeSnapScroll);
      tabManager.addEndCallback(addSnapScroll);
    }

    return () => {
      tabManager.clearBeginCallbacks();
      tabManager.clearEndCallbacks();
    }
  });

  return (
    <>
      <Empresa />
      <Atividades />
      <Casos />
      <Contato />
      <MessageBlock />
    </>
  );
}

export default Home;
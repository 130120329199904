import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { tabManager } from '../../helpers/tabManager';
import { DesktopHeader } from './desktopHeader';
import { MobileHeader } from './mobileHeader';
import { useMediaQuery } from 'react-responsive';

export const Header = () => {
  const [tab, setTab] = useState('empresa');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    tabManager.registerChangeTabEvent((tabName) => {
      setTab(tabName);
    });
  });

  return isMobile ?
    <MobileHeader tab={tab} /> :
    <DesktopHeader tab={tab} />
}
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import Home from './home';
import { Header } from './header/header';
import AOS from 'aos';
import { tabManager } from '../helpers/tabManager';


const App = () => {
	useEffect(() => {
		AOS.init();
		tabManager.createAOSEvents();
		return () => tabManager.removeAOSEvents();
	});

	return (
		<>
			<Header />
			<Home path='/' />
		</>
	);

}

export default App;

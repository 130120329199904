import { scroller, Events, animateScroll } from 'react-scroll';

class TabManager {
  constructor() {
    this.currentTab = 'home';
    this.tabs = ['home', 'empresa', 'atividades', 'casos', 'contato'];
    this.tabNames = [undefined, 'Empresa', 'Atividades', 'Cases', 'Contato'];
    this.onBeginCallbacks = [];
    this.onEndCallbacks = [];
    this.startBeginCallbacks();
    this.startEndCallbacks();
  }

  addBeginCallback(callback) {
    this.onBeginCallbacks.push(callback);
  }

  addEndCallback(callback) {
    this.onEndCallbacks.push(callback);
  }

  clearBeginCallbacks() {
    Events.scrollEvent.remove('begin');
  }

  clearEndCallbacks() {
    Events.scrollEvent.remove('end');
  }

  startBeginCallbacks() {
    Events.scrollEvent.register('begin', this.onBeginScroll);
  }

  startEndCallbacks() {
    Events.scrollEvent.register('end', this.onEndScroll);
  }

  onEndScroll = (to, ele) => {
    this.onEndCallbacks.forEach(callback => {
      callback(to, ele);
    });
  }

  onBeginScroll = (to, ele) => {
    this.onBeginCallbacks.forEach(callback => {
      callback(to, ele);
    })
  }

  scrollToTop() {
    animateScroll.scrollToTop();
  }

  getMainBlocks() {
    this.mainBlocks = {};
    this.tabs.forEach(tab => this.mainBlocks[tab] = document.getElementById(tab));
  }

  scrollToMainBlock = (blockName) => {
    if (!this.mainBlocks) {
      return;
    }

    const block = this.mainBlocks[blockName];
    const name = block.attributes.name.nodeValue;
    scroller.scrollTo(name, {
      duration: 1500,
      smooth: true
    });
  }

  registerChangeTabEvent(evt) {
    this.changeTabEvent = evt;
    this.changeTabEvent(this.currentTab);
  }

  handleAnimatedInBlock = ({ detail }) => {
    const id = detail.id.split('-')[0];
    if (id) {
      this.currentTab = id;
      this.changeTabEvent && this.changeTabEvent(this.currentTab);
    }
  }

  handleAnimatedOutBlock = ({ detail }) => {
    const idSplit = detail.id.split('-');
    const id = idSplit[0];
    if (id && idSplit.length === 1) {
      const idx = this.tabs.indexOf(id);
      this.currentTab = this.tabs[idx - 1];
      this.changeTabEvent && this.changeTabEvent(this.currentTab);
    }
  }

  createAOSEvents() {
    document.addEventListener('aos:in', this.handleAnimatedInBlock);
    document.addEventListener('aos:out', this.handleAnimatedOutBlock);
  }

  removeAOSEvents() {
    document.removeEventListener('aos:in', this.handleAnimatedInBlock);
  }
}

export const tabManager = new TabManager();
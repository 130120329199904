import { h } from 'preact';
import { useState } from 'preact/hooks';

export const messageInfo = {
  show: false,
  msg: ''
};
  
let closeOnTimer;

export const MessageBlock = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  messageInfo.update = (s, msg) => {
    setShow(s);
    setMessage(msg);
    if (msg) {
      if (closeOnTimer) {
        clearTimeout(closeOnTimer);
      }
      closeOnTimer = setTimeout(() => setShow(false), 4000);
    }
  }

  return (
    show &&
    <div class='message-block'>
      { message }
    </div>
  );
}
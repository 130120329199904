import { h } from 'preact';
import { Element } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Denise from '../../assets/Denise.png';
import Art from '../../assets/Artboard1.png';

export const Empresa = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <Element data-aos='fade' name='home' id='home' class='block'>
        <div class='empresa title-container block-content'>
          <svg class='svg-title' viewBox='0 0 600 300' xmlns='http://www.w3.org/2000/svg'>
            <circle fill='#fead34' cx='450' cy='150' r='150' />
            <text class='text' x='0' y='200'>Estratégia</text>
          </svg>
          <h3 class='svg-subtitle'>
            Alto desempenho nos negócios nunca vem por acaso,
            ele é resultado do esforço e engajamento de todos, focados em atender consistentemente aos
            requisitos do cliente e aumentar sua satisfação
          </h3>
        </div>
      </Element>
      <Element data-aos='fade' id='empresa' name='empresa' class='block dark'>
        {
          isMobile ?
            (
              <div class='block-content'>
                <div class='title-container'>
                  <h1 class='title'>Empresa</h1>
                  <div class='art-box'>
                    <img src={Art} />
                  </div>
                </div>
                <p>
                  Atuando no mercado há 18 anos, a Kapper é uma empresa de apoio aos gestores na implementação
                  do Gerenciamento por Processos suportados por Sistemas de Gestão à serviço da Estratégia Organizacional,
                  gerando resultados tangíveis para os clientes, muito além da conquista de um selo ou certificação.
                </p>
                <p>
                  Trabalhamos na estruturação ou revisão de práticas e sistemas de gestão empresarial,
                  enfocando aspectos de estratégia, qualidade, meio ambiente, e saúde e segurança,
                  utilizando as melhores práticas de implementação de normas internacionais com a experiência de processos
                  implantados em empresas dos mais diversos portes e segmentos de atuação.
                </p>
              </div>
            ) :
            (
              <div class='block-content'>
                <div class='flex-center'>
                  <div class='flex1'>
                    <h1 class='title'>Empresa</h1>
                    <p>
                      Atuando no mercado há 18 anos, a Kapper é uma empresa de apoio aos gestores na implementação
                      do Gerenciamento por Processos suportados por Sistemas de Gestão à serviço da Estratégia Organizacional,
                      gerando resultados tangíveis para os clientes, muito além da conquista de um selo ou certificação.
                      </p>
                    <p>
                      Trabalhamos na estruturação ou revisão de práticas e sistemas de gestão empresarial,
                      enfocando aspectos de estratégia, qualidade, meio ambiente, e saúde e segurança,
                      utilizando as melhores práticas de implementação de normas internacionais com a experiência de processos
                      implantados em empresas dos mais diversos portes e segmentos de atuação.
                      </p>
                  </div>
                  <div class='flex-center flex-column flex1'>
                    <img class='w100' src={Denise} />
                    <p class='alt'>Denise Kapper Ramos - Consultora</p>
                  </div>
                </div>
              </div>
            )
        }
      </Element>
    </>
  );
}
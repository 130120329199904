import { h } from 'preact';
import { Element } from 'react-scroll';
import Art from '../../assets/Artboard3.png';

export const Atividades = () => {

  return (
    <>
      <Element data-aos='fade' name='atividades' id='atividades' class='atividades block'>
        <div class='block-content'>
          <div class='title-container'>
            <h1 class='title'>Atividades</h1>
            <div class='art-box'>
              <img src={Art} />
            </div>
          </div>
          <div>
            <h2 class='subtitle'>Gestão de Processos</h2>
            <p>
              A sua análise estruturada permite melhorar os processos existentes, e ainda,
              reduzir custos no desenvolvimento de produtos e serviços, melhorando o desempenho da organização,
              além de ser uma excelente ferramenta para possibilitar o melhor entendimento dos processos
              atuais e eliminar ou simplificar aqueles que necessitam de mudanças. Obter processos estáveis,
              padronizados, e sob controle, onde é possível prever desvios e tomada de ações de correção,
              corretivas e preventivas. Padronizar a maneira de trabalhar, promovendo a estabilização
              do processo e a previsibilidade dos indicadores de desempenho e, minimizando a variabilidade do
              processo e do resultado esperado. A padronização serve como material didático imprescindível
              às atividades de treinamento da função.
                    </p>
            <p>
              Implementar a Gestão de Processos com foco na Cadeia de Valor tem como objetivo mapear
              as atividades da cadeia que determinam os custos, afetam os lucros e criam valor para os clientes.
              Este é o principal motivo pelo qual a ferramenta pode ajudar a empresa a entender quais são suas
              fontes de valor.
                    </p>
            <ul>
              <li>Negócio – Identificação da Cadeia de Valor - Identificação dos Processos de Suporte e de Gerenciamento</li>
              <li>Mapeamento de Processos</li>
              <li>Padronização dos Processos</li>
              <li>Definição e Gerenciamento dos Indicadores Táticos e Operacionais</li>
            </ul>
          </div>
        </div>
      </Element>
      <Element data-aos='fade' name='atividades-1' id='atividades-1' class='atividades block'>
        <div class='block-content'>
          <h2 class='subtitle'>Sistemas de Gestão</h2>
          <p>
            A implantação de Sistemas de Gestão (Qualidade, Meio Ambiente, Saúde e Segurança,
            Qualidade em Laboratórios. Gestão de Projetos, Segurança da Informação e outros),
            incorporam práticas de gestão, de forma a torná-las mais flexíveis, mais organizadas e mais eficientes,
            gerando valor para stakeholders e shareholders.
            A integração dos Sistemas de Gestão através de um modelo simples oportuniza as empresas uma
            única estrutura para todas as normas e especificações de sistemas de gestão que adotam.
                  </p>
          <p>
            O principal objetivo é simplificar a implementação de múltiplos sistemas e sua respectiva
            avaliação de conformidade, oportunizando a melhoria em procedimentos, instruções e
            controles operacionais, simplificando a documentação, integrando procedimentos redundantes,
            atualizando informações defasadas e consolidando documentos, melhorando a performance e
            reduzindo o esforço de operação dos sistemas integrados de gestão.
                  </p>
          <ul>
            <li>Implementação, Certificação e Manutenção de normas de Sistemas de Gestão (ISO 9001, ISO 14001, ISO 45001, ISO 17025, ...) através de metodologias de gestão</li>
            <li>Treinamento e desenvolvimento de equipes</li>
            <li>Aplicação das metodologias MASP, banco de Ideias, benchmarking</li>
          </ul>
        </div>
      </Element>
      <Element data-aos='fade' name='atividades-2' id='atividades-2' class='atividades block'>
        <div class='block-content'>
          <h2 class='subtitle'>Explicitação da Estratégia</h2>
          <p>
            Aplicar como base para gestão a definição de seu negócio, missão, visão e valores,
            e análise de cenários. Esta análise tem como objetivo definir a ligação entre os pontos fortes e
            fracos internos da empresa que determinam a evolução do negócio e as relações com o
            ambiente onde está inserida: o mercado; a concorrência e os clientes.
            É com base nesta análise que os gestores conseguirão identificar quais são as grandes prioridades
            estratégicas da empresa e terão informações para traçar objetivos estratégicos coerentes
            com as metas globais e amplas da organização, diretamente relacionadas a missão da empresa.
            Os Objetivos Estratégicos devem ser desdobrados em Objetivos Táticos que abrangem os
            Processos da organização, e estes em Objetivos Operacionais,
            voltados para a execução de suas operações.
                  </p>
          <ul>
            <li>Negócio/Posicionamento da Empresa</li>
            <li>Missão, Visão e Valores</li>
            <li>Prontidão Estratégica - Riscos e Oportunidades Internos e Externos</li>
            <li>Direcionamento Estratégico - Projetos de Mudanças Estratégicas</li>
            <li>Objetivos Estratégicos, Táticos e Operacionais</li>
            <li>Vigilância Estratégica - Acompanhamento e Gerenciamento dos Indicadores Estratégicos</li>
          </ul>
        </div>
      </Element>
    </>
  );
}
import { h } from 'preact';
import { useState } from 'preact/hooks';
import logo from '../../assets/logo.png'
import menu from '../../assets/menu.svg';
import { Menu } from './menu';
import { tabManager } from '../../helpers/tabManager';

export const MobileHeader = (props) => {
  const [isMenuOpen, switchMenu] = useState(false);

  return (
    <>
      <header class='mobile-header'>
        <div class='content'>
          <div class='logo-container'>
            <img src={logo} onClick={() => tabManager.scrollToMainBlock('home')} />
          </div>
          <img onClick={() => switchMenu(!isMenuOpen)} class='menu-button' src={menu} />
        </div>
      </header>
      <Menu isOpen={isMenuOpen} tab={props.tab} closeMenu={() => switchMenu(false)} />
    </>
  );
}
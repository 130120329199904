import { h } from 'preact';
import { Element } from 'react-scroll';
import Art from '../../assets/Artboard2.png';
import Memphis from '../../assets/memphis.png';
import Getnet from '../../assets/getnet.png';
import Melita from '../../assets/melita.png';
import Datacom from '../../assets/datacom.png';
import Urban from '../../assets/urban.svg';

export const Casos = () => {
  return (
    <>
      <Element data-aos='fade' name='casos' id='casos' class='block'>
        <div class='block-content'>
          <div class='title-container'>
            <h1 class='title'>Cases</h1>
            <div class='art-box'>
              <img src={Art} />
            </div>
          </div>
          <div class='logo-block'>
            <img src={Memphis} />
          </div>
          <p>
            Foram 15 anos como colaboradora. Uma relação de aprendizado, dedicação e gratidão.
            Fui acolhida como estagiária no Laboratório de Análises e Apoio Técnico,
            dando meus primeiros passos numa trilha que me levou a ser Gerente da Área de Engenharia Industrial,
            de Qualidade e Meio Ambiente. Na Memphis, descobri o que hoje descrevo como meu propósito profissional:
            auxiliar as pessoas e as empresas a alcançarem seus melhores resultados,
            através de processos relevantes e organizados por elas e para elas.
          </p>
          <p>
            Sob minha responsabilidade, ocorreu a implantação e manutenção do sistema de gestão integrado –
            certificação ISO 14001 em 2001.
          </p>
          <p>
            Em 2003,  a empresa que abriu as suas portas para o início da minha vida profissional,
            tornou-se a primeira cliente da KAPPER, reforçando a relação de confiança que construímos ao longo dos anos.
            Conquistamos juntos a certificação ISO 9001:2000, desta vez como cliente e Consultora.
          </p>
          <p>
            E, até 2010, continuei ao lado da Memphis, contribuindo com a melhoria de seu Sistema Integrado de Gestão.
            Nesta época, a Kapper já dividia desafios e vitórias com outras consultorias parceiras.
            A semente que havia sido plantada com amor e coragem para empreender num vôo solo,
            já havia germinado e dado frutos e eu não estava mais sozinha,
            mas sim acompanhada por um time de consultores que junto comigo estavam empenhados em
            contribuir para o desenvolvimento das pessoas e a prosperidade dos negócios.
          </p>
        </div>
      </Element>
      <Element data-aos='fade' name='casos-1' id='casos-1' class='block'>
        <div class='block-content'>
          <div class='logo-block'>
            <img src={Melita} />
          </div>
          <p>
            Trabalhar com essa empresa que se dedica a dar um toque especial às nossas vidas através de seus cafés
            tradicionais e de altíssima qualidade é um grande prazer! Nossa parceria começou em 2015.
          </p>
          <p>
            A MELITTA tem duas Unidades Beneficiadoras de café: uma na cidade de Bom Jesus/RS e outra em Avará/SP. 
            O desafio de identificar e avaliar como cada uma dessas unidades deveria se organizar para atender aos 
            requisitos legais ambientais específicos de cada Estados foi intenso e estimulante. 
            E, sempre acompanhados por um delicioso café Melitta fresquinho, 
            que desperta os sentidos e nos faz estar mais alertas e atentos, vencemos juntos e seguimos sempre 
            com as atualizações e melhorias necessárias.
          </p>
          <p>
            A entrega de relatórios aos órgãos ambientais fiscalizadores e a preparação para as auditorias 
            ambientais compulsórias, são tarefas sistemáticas, que precisam de atenção, método e disciplina, 
            que são características essenciais do trabalho da KAPPER, abraçadas com entusiasmo pela MELITTA. 
            O trabalho sempre flui melhor quando existe sintonia.
          </p>
        </div>
      </Element>
      <Element data-aos='fade' name='casos-2' id='casos-2' class='block'>
        <div class='block-content'>
          <div class='logo-block'>
            <img src={Getnet} />
          </div>
          <p>
            Com a intenção de ser a melhor plataforma de negócios do mercado,
            alcançando todo ecossistema de pagamentos, a GETNET contribui para transformar pessoas e negócios de forma simples,
            ágil e segura. E escolheu a KAPPER para estar ao seu lado desde 2015.
          </p>
          <p>
            Uma nova experiência, que nos transportou do mundo da indústria para o desafiador universo dos serviços,
            abrangendo soluções físicas e digitais que nos exigiram atenção, atualização e entendimento profundo.
            E nós adoramos começar algo novo!
          </p>
          <p>
            Em um primeiro projeto, em parceria com a Berenice Kuhn,
            ocorreu a implementação do Sistema de Gestão da Qualidade e a Certificação ISO 9001.
            O perfil inovador da GETNET nos trouxe a oportunidade de integrar três Sistemas de Gestão: ISO 9001, ISO 10002 e a ISO 27001.
            Um sucesso que celebramos em 2020!
          </p>
          <p>
            E agora, seguimos identificando e trabalhando no aprimoramento contínuo dos sistemas que visam dar suporte ao
            cumprimento dos objetivos estratégicos da  GETNET. Vamos juntos!
          </p>
        </div>
      </Element>
      <Element data-aos='fade' name='casos-3' id='casos-3' class='block'>
        <div class='block-content'>
          <div class='logo-block'>
            <img src={Datacom} />
          </div>
          <p>
            A parceria com a maior fabricante de equipamentos para telecomunicação da América Latina começou em 2014, 
            quando a Datacom já contava com a Certificação ISO 9001. 
          </p>
          <p>
            Naquela época, nosso desafio era conduzir o processo de Implementação e Certificação dos Sistemas de Gestão Ambiental, 
            ISO 14001 , e de Saúde e Segurança Ocupacional, OHSAS 18001. Sabíamos que o verdadeiro “coração” dos Sistemas de Gestão”, 
            seria a etapa de identificação e avaliação dos aspectos e impactos ambientais e perigos e riscos ocupacionais. 
            Também estávamos certos de que somente uma equipe motivada poderia construir esta etapa conosco.
          </p>
          <p>
            Dinâmicas dirigidas e acompanhamentos periódicos deram voz e ritmo ao nosso trabalho, 
            que aconteceu de forma colaborativa, envolvendo representantes de todas as áreas da empresa. 
            Isso facilitou a elaboração dos documentos de controle operacional e o planejamento dos monitoramentos requeridos.
          </p>
          <p>
            Em 2015, recebemos os auditores externos e comemoramos juntos as duas Certificações. 
            A parceria se fortalece a cada ano e continuamos atuando como Consultores na Gestão de Processos e na 
            Explicitação da Estratégia.
          </p>
          <p>
            Em 2020, apoiamos a equipe para o upgrade na Norma ISO 45001, antes do prazo limite de 2021. 
            O Certificado foi obtido, confirmando a sinergia que existe entre as equipes da DATACOM e da KAPPER. 
            E assim seguimos em frente, firmes e fortes, sempre com novos motivos para nos encher de orgulho e satisfação.
          </p>
        </div>
      </Element>
      <Element data-aos='fade' name='casos-4' id='casos-4' class='block'>
        <div class='block-content'>
          <div class='logo-block'>
            <img src={Urban} />
          </div>
          <p>
            Quanta alegria trabalhar com esse novo parceiro que acredita firmemente que o alimento é a 
            alavanca mais forte para otimizar a saúde das pessoas e a sustentabilidade do planeta. 
            Trabalhando com produto plant based , apoiando a agricultura familiar e orgânica, essa empresa inovadora e 
            com um propósito admirável  embarcou numa jornada com a KAPPER, que vai conduzi-la à implementação de um 
            Sistema de Gestão da Qualidade, integrando as etapas de Inspeção, Controle, Garantia e Gestão da Qualidade.
          </p>
          <p>
            Uma honra estar ao lado da Urban Farmacy trabalhando por mais bem-estar e saúde para todos. 
            No que depender da KAPPER, essa parceria já tem um sabor natural de sucesso!
          </p>
        </div>
      </Element>
    </>
  );
}
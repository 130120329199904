import { h } from 'preact';
import logo from '../../assets/logo.png'
import { tabManager } from '../../helpers/tabManager';

export const Menu = (props) => {
  const changeTab = (tabName) => {
    tabManager.scrollToMainBlock(tabName);
    props.closeMenu();
  }

  return (
    <div class={`menu ${props.isOpen ? 'open' : ''}`}>
      <div class='mask' onClick={props.closeMenu} />
      <div class='content'>
        <div class='logo-container flex-center'>
          <img src={logo} />
        </div>
        <div class='tabs'>
          {
            tabManager.tabs.map((tabId, idx) => tabManager.tabNames[idx] && (
              <div key={tabId}
                onClick={() => changeTab(tabId)}
                class={`tab ${props.tab === tabId ? 'active' : ''}`}
              >
                {tabManager.tabNames[idx]}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
import { h } from 'preact';
import logo from '../../assets/logo.png'
import { tabManager } from '../../helpers/tabManager';

export const DesktopHeader = (props) => {
  return (
    <header class='header'>
      <div class='content'>
        <div class='logo-container'>
          <img src={logo} onClick={() => tabManager.scrollToMainBlock('home')} />
        </div>
        <div class='tabs'>
          {
            tabManager.tabs.map((tabId, idx) => tabManager.tabNames[idx] && (
              <div key={tabId}
                onClick={() => tabManager.scrollToMainBlock(tabId)}
                class={`tab ${props.tab === tabId ? 'active' : ''}`}
              >
                {tabManager.tabNames[idx]}
              </div>
            ))
          }
        </div>
      </div>
    </header>
  );
}